// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
//import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// in order to be able to fire Rails custom events in inline scripts called through ajax,
// we have to bind Rails to window to make it globally available in client context.
window.Rails = Rails

// requires jquery:
//require('src/nprogress-rails-turbolinks5')

// It's not necessary to grab a reference to the imported variable because we only care
// about the side effect of emitting the file for Rails to reference in the view.
const images = require.context('../images', true)
// MainAppImages is set as alias by Siteware's webpacker config
const mainAppImages = require.context('MainAppImages', true )

const behaviors = require.context('src/behaviors', true)
behaviors.keys().forEach(behaviors)
